import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
	BrowserRouter, Route, Routes, Navigate,
} from 'react-router-dom'
import CIcon from '@coreui/icons-react'
import { UFunc } from './helpers/functions'
import useToken from './helpers/useToken'
import './assets/scss/style.scss'
import "react-image-gallery/styles/css/image-gallery.css";
import { 
	MyLoader,MyToaster,
	MyDialogsession,
} from './components/index'
import useSatusehat from './helpers/useSatusehat'

//--PAGES--/
import { 
	Login,
	Error500,
	Layout
} from "./pages/index"
//--END PAGES--/

/*//---CONTAINERS--/
const Layout = React.lazy(() => import('./pages/Layout'))
//---END CONTAINERS--*/

const pjson	= require('../package.json');
const App 	= (props) => {
	const { token, setToken } 			= useToken();
	const { removeToken } 				= useSatusehat(props);
	const uDispatch    					= useDispatch();

	const uToastlist					= useSelector(state => state.listToast);
	const [uRoutes,setRoutes]			= React.useState(JSON.parse(localStorage.getItem("routeslist")) || "[]");
	const uActiveRoute  				= useSelector(state => state.activeRoute);
	const uRouteProfileArr				= useSelector(state => state.profileRoute); 
	const uSystemsettingObj				= useSelector(state => state.gSystemsetting);
	const uEfekapp						= useSelector(state => state.effectApp);
	const uEfeksessionapp				= useSelector(state => state.effectsessionApp) || false;
	const uConfigappsObj				= useSelector(state => state.uConfigappsObj) || {};

	const [uIsTotopshow,setTotopshow]	= React.useState(false);
	const [uBahasaob,setBahasaob]		= React.useState({});
	const [isApploading,setApploading]	= React.useState(false);
	const [isLoading, setLoading]      	= React.useState(false);
	const [isInitKoneksi, setInitKoneksi ]= React.useState(true);

	//--TIMEOUTSESSION--/
	const uTimeouttimer							= parseInt(useSelector(state => state.gTimesessionlimit)) || 1800;
	const [uISSessionaktif,setSessionaktif] 	= React.useState(false);
	const [uCoundownsession,setCoundownsession]	= React.useState(uTimeouttimer);
	const [uSessionTimeStart,setSessionTimeStart]= React.useState();
	//--END TIMEOUTSESSION--/

	//--DLG_FORM--/
	const [uIsDlgsessionshow,setDlgsessionshow]		= React.useState(false);
	const [uSessionISNotify,setSessionNotify]  		= React.useState(false);
	const [uKlikDlgsessionYA,setKlikDlgsessionYA]	= React.useState(false);
	//--END DLG_FORM--/

	//---DETIL_PAGE--/
	const uDetilPageArr 		= [
		{path:"/subpasien/pasien/daftarantri/:tabel_id",name:"daftarantri",owner:"pasien",},
		{path:"/subpasien/pasien/rekammedis/:tabel_id",name:"rekammedis",owner:"pasien",},
		{path:"/subpasien/pasien/periksa/:tabel_id",name:"periksa",owner:"pasien",},
		{path:"/subpasien/pasien/fotopasien/:tabel_id",name:"fotopasien",owner:"pasien",},

		{path:"/subpasien/pasien/daftarantri/:tabel_id",name:"daftarantri",owner:"antrianpasien",},
		{path:"/subpasien/pasien/rekammedis/:tabel_id",name:"rekammedis",owner:"antrianpasien",},
		{path:"/subpasien/pasien/periksa/:tabel_id",name:"periksa",owner:"antrianpasien",},
		{path:"/subpasien/pasien/fotopasien/:tabel_id",name:"fotopasien",owner:"antrianpasien",},

		{path:"/subpasien/pasien/daftarantri/:tabel_id",name:"daftarantri",owner:"perawatjadwal",},
		{path:"/subpasien/pasien/fotopasien/:tabel_id",name:"fotopasien",owner:"perawatjadwal",},

		{path:"/subpasien/pasien/daftarantri/:tabel_id",name:"daftarantri",owner:"statuspasien",},
		{path:"/subpasien/pasien/rekammedis/:tabel_id",name:"rekammedis",owner:"statuspasien",},
		{path:"/subpasien/pasien/periksa/:tabel_id",name:"periksa",owner:"statuspasien",},
		{path:"/subpasien/pasien/fotopasien/:tabel_id",name:"fotopasien",owner:"statuspasien",},
		{path:"/subbilling/kasir/bayar/:tabel_id",name:"bayar",owner:"statuspasien",},

		{path:"/subpasien/pasien/daftarantri/:tabel_id",name:"daftarantri",owner:"antrianexpired",},
		{path:"/subpasien/pasien/rekammedis/:tabel_id",name:"rekammedis",owner:"antrianexpired",},
		{path:"/subpasien/pasien/periksa/:tabel_id",name:"periksa",owner:"antrianexpired",},
		{path:"/subpasien/pasien/fotopasien/:tabel_id",name:"fotopasien",owner:"antrianexpired",},
		
		{path:"/subbilling/kasir/bayar/:tabel_id",name:"bayar",owner:"kasir",},
		{path:"/subbilling/kasir/bayar/:tabel_id",name:"bayar",owner:"kasirpiutang",},
		{path:"/subbiling/kasirasuransi/previewbayar",name:"previewasuransi",owner:"kasirasuransi",},
		{path:"/subdasar/user/setrole/:user_id",name:"setrole",owner:"user",},
	]
	//---END DETIL_PAGE--/

	//---HANDEL--/
	const hdlToggleDlgsession = () => { setDlgsessionshow(false)}
	const hdlKlikDlgsessionYA = () => {
		setKlikDlgsessionYA(true);
		apiProsessession(); 
	}
	const hdlKlikToTop = () => { window.scrollTo({ top: 0, behavior: "smooth" }); };
	const hdlKDownField=(_EV)=>{
		if (_EV.keyCode === 13 && (_EV.target.nodeName === "INPUT"
			|| _EV.target.nodeName === "SELECT"
			|| _EV.target.nodeName === "TEXTAREA")
		){
			if (_EV.target.nodeName === "TEXTAREA") {
				if(_EV.shiftKey) return;
			}

			var form      = _EV.target.form;
			/*console.log("("+uComponentname+") addEventListener => form 1 "+form);
			if(isNaN(form)) {
				console.log("("+uComponentname+") addEventListener => form 1a "+form);
			}//-*/
			if(UFunc.isEmpty(form)) return;
			//console.log("("+uComponentname+") addEventListener => form 2 "+form);
			var index     = Array.prototype.indexOf.call(form, _EV.target);
			var vJmlLoop  = 0;
			var vFocusedBl= false;
			do{
				index++;
				vJmlLoop++;
				var vElement  = form.elements[index];
				if(vElement && !vElement.disabled 
					&& vElement.style.visibility !== "hidden"
					&& vElement.style.display !== "none"
				) {
					vFocusedBl = true;
					vElement.focus();
				}
				/*console.log("("+uComponentname+") addEventListener => ID "+(vElement.id && vElement.id));
				console.log("("+uComponentname+") addEventListener => visibility : "+(vElement.style.visibility));
				console.log("("+uComponentname+") addEventListener => display : "+(vElement.style.display));//-*/
			} while(!vFocusedBl && vJmlLoop <= 5)
			_EV.preventDefault();
		}
	}
	const hdlScroll=()=>{
		if (window.pageYOffset > 300) setTotopshow(true);
		else setTotopshow(false);
	}
	const hdlScrollWindow=(e)=>{
        const windowHeight 	= "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body 			= document.body;
        const html			= document.documentElement;
        const docHeight 	= Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight-5) {
			uDispatch({type: "set", gIsScrollBottom: true});
		} else {
			uDispatch({type: "set", gIsScrollBottom: false});
		}
	}
	//---END HANDEL--/

	//---GLOBAL_PROP_FUNCTION--/
	const clearStorage = () => {
		//localStorage.removeItem("cabanglist");
		localStorage.removeItem("routeslist");
		localStorage.removeItem("listkeyword");

		setDlgsessionshow(false);
		setSessionaktif(false);
		uDispatch({type: "set", gBillingperiksaObj: undefined});
		uDispatch({type: "set", gInitTabelSelected: {}});

		removeToken();
	}
	const showToast = (_PESAN, _TIPE="INFO") => {
		const vTmps  = { type : _TIPE, teksIsi : _PESAN };
		uDispatch({type: "set", listToast: [...uToastlist, vTmps]});
		//uDispatch({type: "set", listToast: vTmps});
	};
	const prosesExpired = () => {
		clearStorage();

		showToast(uBahasaob.pesan_hasexpired || pjson.mydefault.msgExpired);
		setToken("");
		setLoading(false);
	}
	const prosesSessioncount = () => {
		if(!uSessionTimeStart) return;
		//if(uSessionTimeStart === undefined) return;

		const vTimecekDT	= new Date();
		const vSelisihDTK	= parseInt((vTimecekDT.getTime() - uSessionTimeStart.getTime()) / 1000 );
		
		//console.log("(App - prosesSessioncount) vSelisihDTK => "+vSelisihDTK);
		let vCountsession	= uTimeouttimer - vSelisihDTK; 
		if(vCountsession === uCoundownsession) vCountsession++;

		setCoundownsession(vCountsession);
		//console.log("(App - prosesSessioncount) uTimeouttimer => "+uTimeouttimer);
	}
	const initDashboard = () =>{
		/*//--
		1. Hapus Dashboard 6 bulan
		2. JIKA Tgl Satu Bulan Baru KOSONG,Tanggal BLN Kemarin Dihapus
		3. Cek setiap 3 jam, jika sudah tiga jam, Tanggal BLN Ini Dihapus.//-->Ini dilakukan di DASHBOARD
		//-*/
	
		const vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		const vTimestampTS		= vDatadashboardObj.timestampTS;
		if(!vTimestampTS) return;

		const vTglnowDT 		= new Date();
		const vDataArr			= vDatadashboardObj.data_arr || [];
		const vTglsatuDT		= new Date(vTglnowDT.getFullYear(),vTglnowDT.getMonth(),1);

		let vTgl6monthDT		= new Date();
		new Date(vTgl6monthDT.setMonth(vTgl6monthDT.getMonth() - 6));
		
		let vTglLastmonthDT 	= new Date();
		new Date(vTglLastmonthDT.setMonth(vTglLastmonthDT.getMonth() - 1));
		const vTgl1LastmonthDT	= new Date(vTglLastmonthDT.getFullYear(),vTglLastmonthDT.getMonth(),1);
		
		const vTglsatuTS		= vTglsatuDT.valueOf();
		const vTgl6monthTS		= vTgl6monthDT.valueOf();
		const vTgl1LastmonthTS	= vTgl1LastmonthDT.valueOf();

		/*console.log("(App - initDashboard) vTglnowDT => "+vTglnowDT);
		console.log("(App - initDashboard) vTgl6monthDT => "+vTgl6monthDT);
		console.log("(App - initDashboard) vTgl1LastmonthDT => "+vTgl1LastmonthDT);//-*/

		//--HAPUS_DATA_LEWAT_6_BULAN--/
		let vFilteredArr		= vDataArr.filter((vItems,vKeys) => {
			const vTglfilterTS	= UFunc.toDate(vItems.tgl_filter).valueOf();
			return (vTglfilterTS > vTgl6monthTS);
		})
		//--END HAPUS_DATA_LEWAT_6_BULAN--/

		const vDatanowArr = vFilteredArr.filter(vItems => {
			const vTglfilterTS 	= UFunc.toDate(vItems.tgl_filter).valueOf();
			return vTglfilterTS === vTglsatuTS;
		})

		//--JIKA_BULAN_INI_MASIH_KOSONG_HAPUS_BULAN_LALU--/
		if(vDatanowArr.length <= 0) {
			vFilteredArr = vFilteredArr.filter((vItems,vKeys) => {
				const vTglfilterTS	= UFunc.toDate(vItems.tgl_filter).valueOf();
				return (vTglfilterTS !== vTgl1LastmonthTS);
			})
		}
		//--END JIKA_BULAN_INI_MASIH_KOSONG_HAPUS_BULAN_LALU--/

		vDatadashboardObj.data_arr 		= vFilteredArr;
		localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
	}
	//---END GLOBAL_PROP_FUNCTION--/

	//---REST_API--/
	const apiLoadinit = () => {
 		//setSessionaktif(false);
		setApploading(true);
		setInitKoneksi(true);

		/*//---TEST_FRONTEND--/
		let vTimeout	= setTimeout(function(){
			setApploading(false);
			//setInitKoneksi(false);
			//setToken("")
		},2000); return;
		//---END TEST_FRONTEND--*/

		const vDATAS= JSON.stringify({ 
			x:"x",
			send_tokenauth:token, 
		});
		const vURLs	= pjson.homepage+"api/api_init/ld_init";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json();}
		}).then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			//console.log("(App - apiLoadinit) output_string.cabangatribut : "+(output_string.cabangatribut));
			setApploading(false);
			uDispatch({type: "set", uConfigappsObj:JSON.parse(output_string.config_aps||"{}")});
			uDispatch({type: "set", gSystemsetting:JSON.parse(output_string.systemsetting||"{}")});
			uDispatch({type: "set", gCabangatribut:JSON.parse(output_string.cabangatribut||"{}")});
			
			localStorage.setItem("cabanglist", output_string.cabanglist||"[]"); 
			setBahasaob(JSON.parse(output_string.langs||"[]"));
			if(output_string.tampil) {
				if(token) {
					uDispatch({type: "set", listNav: JSON.parse(output_string.nav || "[]")});
					if(output_string.routes) {
						const vRouteLoadArr = JSON.parse(output_string.routes||"[]");
						const vNewRoutes	= [...uRouteProfileArr,...vRouteLoadArr];
						localStorage.setItem("routeslist", JSON.stringify(vNewRoutes)); 
					}

					if(vTimeDiff > 500) setSessionaktif(true);
					else {
						let vTimeout = setTimeout(()=>{ clearTimeout(vTimeout); setSessionaktif(true);},500)
					}
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(App - apiLoadinit) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;

				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				if(token) { 
					showToast(uBahasaob.pesan_hasexpired || pjson.mydefault.msgExpired);
					setToken(""); 
				}
			}
		}).catch((error) =>{
			setInitKoneksi(false);
			setApploading(false);
			console.log("(App - apiLoadinit) catch-error : "+error);
		});
	}
	const apiProsessession = () => {
		const vEldisabled = document.getElementById("btnDlgsessionYa")
		vEldisabled && (vEldisabled.disabled=true);
 		setSessionaktif(false);
		setLoading(true)

		const vDATAS	= JSON.stringify({ send_tokenauth:token });
		const vURLs	= pjson.homepage+"api/api_init/pr_session";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { return response.json(); }})
		.then((output_string) => {
			vEldisabled && (vEldisabled.disabled=false);
			setLoading(false);
			if(output_string.sukses) {
				setSessionaktif(true);
				hdlToggleDlgsession();
			} else if(output_string.expired) {
				if(token) { 
					showToast(uBahasaob.pesan_hasexpired || pjson.mydefault.msgExpired);
					setToken(""); 
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(App - apiProsessession) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			}
		})
		.catch((error) =>{
			vEldisabled && (vEldisabled.disabled=false);
			setLoading(false);
			console.log("(App - apiProsessession) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}

	//---EFFECT--/
	React.useEffect(() => {
		document.addEventListener("keydown", hdlKDownField);
		document.addEventListener("scroll", hdlScroll);
		window.addEventListener("scroll", hdlScrollWindow);

		//return function cleanup() {
		return () => {
			window.removeEventListener("scroll", hdlScrollWindow);
			document.removeEventListener("keydown",hdlKDownField);
			document.removeEventListener("scroll",hdlScroll);
		}
	});
	React.useEffect(() => {
		localStorage.removeItem("lasturl");

		//---LOAD_BAHASA--/
		apiLoadinit();
		//---END LOAD_BAHASA--/

		//--INIT_DASHBOARD--/
		initDashboard();
		//--END INIT_DASHBOARD--/

		return ()=>{
			setBahasaob([]);
			setSessionaktif(false)
			setDlgsessionshow(false);
		}
	},[]);
	//React.useEffect(()=>{ return () => { setSessionaktif(false) } },[uEfekapp])
	React.useEffect(() => { window.scrollTo({top: 0}); },[uEfekapp]);
	React.useEffect(() => { if(!token) clearStorage(); },[token]);
	React.useEffect(() => {
		//console.log("(App - [uISSessionaktif]) uTimeouttimer => "+uTimeouttimer);
		uDispatch({type: "set", effectsessionApp: uISSessionaktif});

		if(!uISSessionaktif) {
			setDlgsessionshow(false);
			setCoundownsession(uTimeouttimer);
			setSessionNotify(false);
			setSessionTimeStart();
			return;
		}

		setSessionTimeStart(new Date());
	},[uISSessionaktif]);
	//React.useEffect(() => { setCoundownsession(uTimeouttimer) },[uTimeouttimer]);
	React.useEffect(() => {
		//if(!uISSessionaktif) return;
		//console.log("(App - [uSessionTimeStart]) uSessionTimeStart => "+uSessionTimeStart);
		if(!uSessionTimeStart) return;
		
		//prosesSessioncount(); return;

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			prosesSessioncount();
		},1000)
	},[uSessionTimeStart]);//-*/
	React.useEffect(() => {
		if(!uISSessionaktif) return;

		//console.log("(App - [uCoundownsession]) uCoundownsession => "+uCoundownsession);
		//console.log("(App - [uCoundownsession]) uSessionISNotify => "+uSessionISNotify);
		if((uCoundownsession <= 35 && uCoundownsession >= 0) 
			&& !uSessionISNotify && !uIsDlgsessionshow
			) setDlgsessionshow(true);

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			prosesSessioncount();
		},1000); 
	},[uCoundownsession]);
	React.useEffect(() => {
		if(!uIsDlgsessionshow) {
			if(!uKlikDlgsessionYA) setSessionaktif(false);
			return;
		}//-*/

		setKlikDlgsessionYA(false);
		setSessionNotify(true);
	},[uIsDlgsessionshow]);//-*/
	React.useEffect(() => {
		if(uSystemsettingObj.limit_session === undefined) return;
		//console.log("(App - [uSystemsettingObj]) uSystemsettingObj : "+JSON.stringify(uSystemsettingObj));

		uDispatch({type: "set", 
			gTimesessionlimit: parseInt(uSystemsettingObj.limit_session)});
	},[uSystemsettingObj,uSystemsettingObj.limit_session]);
	React.useEffect(() => { uDispatch({type: "set", listBahasa: uBahasaob}); },[uBahasaob]);
	//---END EFFECT--/

	//---FIRSTLOADING--/
	if(isApploading) {
		return (<MyLoader isShow={isApploading}/>);
	}
	//---END FIRSTLOADING--/

	//---JIKA_INTERNET_PROBLEMS--/
	if(!isInitKoneksi) return (
			<BrowserRouter>
			<Routes>
				<Route path="/error500" element={ 
					<Error500
						showToast={showToast}
						setLoading={setLoading}
						{...props}/>
					}/>
				<Route path="*"
					element={<Navigate from="/" to="/error500"/>}/>
			</Routes>
			</BrowserRouter>
		)//>
	//---END JIKA_INTERNET_PROBLEMS--*/

	//---NO_TOKEN_AUTH--/
	if(!token) return (
		<BrowserRouter>
			<MyLoader isShow={isLoading} />
			<MyToaster toastData={uToastlist} />
			<Routes>
				<Route path="/error500" element={ 
					<Error500
						showToast={showToast}
						setLoading={setLoading}
						{...props}/>
					}/>
				<Route path="/login" element={ 
					<Login
						setToken={setToken}
						showToast={showToast}
						setLoading={setLoading}
						{...props}/>
					}/>
				<Route path="*"
					element={<Navigate from="/" to="/login"/>}/>
			</Routes>
		</BrowserRouter>
	)//>
	
	//---END NO_TOKEN_AUTH--*/
	
	const loadDetilpage = (_MAINMENU) => {
	 	if(_MAINMENU.toUpperCase() === ("pasien").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("antrianpasien").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("statuspasien").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("perawatjadwal").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("antrianexpired").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("kasir").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("kasirpiutang").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("kasirasuransi").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("user").toUpperCase() 
	 	) {
	 		//console.log("(App - loadDetilpage) uDetilPageArr => "+JSON.stringify(uDetilPageArr));
	 		return uDetilPageArr.map((vItem,vKey)=>{
				const {path,name,owner} = vItem;

				const vDetilname = name.toLowerCase();
				if(owner.toUpperCase() === _MAINMENU.toUpperCase()) {
	 				//console.log("(App - loadDetilpage) path => "+(path));
					let KomponenDetil;
					try {
						KomponenDetil = require("./pages/detil/"+UFunc.capitalize(vDetilname)).default;
					} catch (ex) {
						//console.log("(App - loadDetilpage) uDetilPageArr.name(ex) = ./detil/"+vDetilname);
					}
					return KomponenDetil && (
						<Route
							key={vKey}
							path={path}
							exact
							name={UFunc.capitalize(vDetilname)}
							element={
								<KomponenDetil
									setToken={setToken}
									showToast={showToast}
									setLoading={setLoading}
									prosesExpired={prosesExpired}
									clearStorage={clearStorage}
									setSessionaktif={setSessionaktif}
									{...props}/>
							}/>
					)	
				}
			})
	 	} 
	}

	//console.log("(App) uActiveRoute = "+uActiveRoute)
	return (
		<>
		<MyLoader isShow={isLoading} />
		<MyToaster toastData={uToastlist} />
		<div style={{zIndex:2000}} className="d-none position-fixed bottom-0 start-0 p-2 text-danger">[{uCoundownsession}]</div>

		{(uConfigappsObj.system_warning||"")!=="" && (
		<div className="position-fixed d-block w-100 h-auto start-0 p-2 fw-bolder fst-italic"
			style={{
				zIndex:1030,
				borderTop:"1px dashed red",
				borderBottom:"1px dashed red",
				backgroundColor: "#DFFF0099",
				color:"red",
				bottom: 0,
			}}>
		<marquee>{uConfigappsObj.system_warning}</marquee>
		</div>
		)}

		<BrowserRouter>
		<Routes>
			{loadDetilpage(uActiveRoute)}
			<Route exact path="*" name="Layout" 
				element={
					<Layout 
						setToken={setToken}
						showToast={showToast}
						setLoading={setLoading}
						prosesExpired={prosesExpired}
						clearStorage={clearStorage}
						setSessionaktif={setSessionaktif}
						{...props}/>
				}/>
			<Route exact path="/" element={<Navigate from="/" to="/dashboard"/>}/>
			<Route exact path="/login" element={<Navigate to="/dashboard"/>}/>
			<Route exact path="/error500" element={<Navigate to="/dashboard"/>}/>
		</Routes>
		{uIsTotopshow &&
			<div onClick={()=>hdlKlikToTop()} className="text-danger classToTop" >
			<CIcon icon="cilArrowCircleTop" height={35} />
			</div>
		}
		</BrowserRouter>

		<MyDialogsession
			onKlikyes={()=>hdlKlikDlgsessionYA()}
			show={uIsDlgsessionshow} 
			toggle={hdlToggleDlgsession} 
			Countertime={uCoundownsession}/>
		</>
    )//>
}

export default App
	